<template>
  <div>
    <maca-titulo-pagina
      icono="icon-suitcase"
      nombrePagina="Roles"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '25px' }" style="margin:30px">
      <div slot="header">
        <div style="margin-left:0px">
          <el-input
            class="buscador"
            prefix-icon="el-icon-search"
            v-model="buscar"
            placeholder="Buscar"
            style="width:300px"
          >
          </el-input>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right:0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo Rol</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Nombre" prop="nombre"></el-table-column>
          <el-table-column
            label="Descripción"
            prop="descripcion"
          ></el-table-column>

          <el-table-column label="Permisos" width="85" align="center">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                plain
                @click="$router.push('/roles/' + props.row.id + '/pantallas/')"
              >
                <i class="el-icon-finished"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                v-if="props.row.protegido === 0"
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
<!--          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" circle @click="eliminar(props.row.id)">
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>-->
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "rol",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/rol/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el rol."
      ).catch(() => null);

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/rol/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Rol borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
};
</script>
