var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "icon-suitcase", nombrePagina: "Roles" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "30px" },
          attrs: { "body-style": { padding: "25px" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("el-input", {
                    staticClass: "buscador",
                    staticStyle: { width: "300px" },
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Buscar"
                    },
                    model: {
                      value: _vm.buscar,
                      callback: function($$v) {
                        _vm.buscar = $$v
                      },
                      expression: "buscar"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono",
                  staticStyle: { "margin-right": "0px" },
                  attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.modalNuevo.abrir()
                    }
                  }
                },
                [_vm._v("Nuevo Rol")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Nombre", prop: "nombre" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Descripción", prop: "descripcion" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Permisos", width: "85", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  circle: "",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/roles/" + props.row.id + "/pantallas/"
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-finished" })]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Editar", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.protegido === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.modalModificar.abrir(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-edit" })]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }